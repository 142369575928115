<script>
export default {
  name: 'ShowInsights',
  components: {
    // Oembed
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      loaded: false,
    };
  },
  computed: {
    selectedProject() {
      return this.$store.state.selectedProject[0];
    },
    projectNameDisplay() {
      const mode = this.projectNameDisplaySetting;
      const project = this.selectedProject;

      const code = mode.includes(0) ? project.code : '',
        key = mode.includes(1) ? project.projectId : '',
        name = mode.includes(2) ? project.name : '';

      let displayName = [code ? code : '', key ? key : '', name ? name : ''];

      if (mode.length === 0) {
        displayName = [project.name];
      }

      return displayName.filter(p => p).join(' ');
    },
    projectNameDisplaySetting() {
      return this.$store.state.projectNameDisplay;
    },
    hasInsights() {
      return (
        this.selectedProject &&
        (this.selectedProject.new_insights || this.selectedProject.insights)
      );
    },
  },
  methods: {
    load() {
      this.loaded = true;
    },
  },
};
</script>

<template>
  <div>
    <div v-if="selectedProject" class="text-h6 px-3 pt-3">
      {{ selectedProject && projectNameDisplay }}
    </div>
    <div
      v-if="hasInsights"
      style="position: relative; height: calc(100vh - 200px); overflow: hidden"
    >
      <v-progress-linear v-if="!loaded" indeterminate color="action" />
      <iframe
        :src="selectedProject.new_insights || selectedProject.insights"
        width="100%"
        style="
          border: 0;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;

          height: 100vh;
        "
        frameborder="0"
        allowfullscreen
        @load="load"
      />
    </div>
  </div>
</template>
